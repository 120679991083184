@import '/src/styles/_typography.scss';

.companyUsersWrapper {
  display: flex;
  flex-direction: column;
}

.companyUsersSegmented {
  @include text-bold;
  margin-bottom: 10px;
  align-self: flex-end;
}
