.wrapper {
  :global {
    .ant-form-item-row {
      position: relative;
      border: 1px solid #d1d1d1;
      border-radius: 6px;
      max-width: 100%;
      padding: 4px 11px;
      margin: 22px 6px 0 6px;
    }
    .ant-form-item-label {
      color: rgba(7, 41, 92, 0.96);
      position: absolute;
      top: -14px;
      left: 6px;
      background-color: white;
      label {
        padding: 2px;
        &::after {
          content: none;
        }
      }
    }
    .ant-checkbox-group {
      label {
        flex-direction: row-reverse;
      }
    }
  }
}
