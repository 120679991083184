@import '/src/styles/colours';

.tabs {
  :global(.ant-tabs-tab) {
    border-radius: 0 6px 0 0 !important;
  }
}

.wrapper {
  margin-bottom: 10px;
  :global(.ant-select) {
    width: fit-content !important;
    min-width: 300px !important;
    max-width: 100% !important;
  }

  .btnPregled {
    background: transparent !important;
    color: $primary-light;
    border: 1px solid $primary-light !important;
    padding: 4px !important;
    border-radius: 5px;

    &:hover {
      color: $brand-dark !important;
      border: 1px solid $brand-dark !important;

      svg {
        color: $brand-dark !important;
      }
    }
  }
}

.postrojenjeSelect {
  margin-left: 10px;
}
