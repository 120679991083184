// spacings
$spacing-0: 0px;
$spacing-5: 5px;
$spacing-10: 10px;
$spacing-15: 15px;
$spacing-20: 20px;
$spacing-25: 25px;
$spacing-30: 30px;
$spacing-40: 40px;

// Percentage variables
$percent-10: 10%;
$percent-20: 20%;
$percent-25: 25%;
$percent-30: 30%;
$percent-40: 40%;
$percent-45: 45%;
$percent-50: 50%;
$percent-60: 60%;
$percent-65: 65%;
$percent-70: 70%;
$percent-75: 75%;
$percent-80: 80%;
$percent-88: 88%;
$percent-90: 90%;
$percent-100: 100%;

// radius
$radius-5: 5px;
$radius-10: 10px;
$radius-20: 20px;

// colors
$color-grey: #ebebeb;
$color-grey-50: #808080;
$color-grey-mid: #bfbfbf;
$color-grey-lightest: #f7f7f7;
$color-grey-text: rgba(#181818, 0.5);
$color-grey-dark: #535353;

$color-green: #5db948;
$color-black: #181818;
$color-black-trans: rgba($color-black, 0.6);

$color-white-trans: #ffffff66;
$color-white-trans-opaque: #ffffffb2;
$color-white: #ffffff;

$color-purple: #7580b7;
$color-orange: #fdb519;
$color-red: #e81e1e;
$color-magenta: #f24d9e;
$color-magenta-light: rgba($color-magenta, 0.3);

$color-green-20: #4dc63733;
$color-orange-20: #f8a41d33;
$color-grey-20: #c6c6c633;

// scrollbar
$scrollbar-radius: $radius-20;
$scrollbar-small-width: $spacing-5;
$scrollbar-width: $spacing-20;

// typography
$h1-height: 72px;
$h2-height: 48px;
$h3-height: 28px;
$p-height: 26px;
$small-height: 17px;
