@use '../../../utils/variables.scss';
@import '/src/styles/colours';

.appFileUploadListTitle {
  margin: variables.$spacing-5 0;
}

.appFileUploadListItem {
  position: relative;
  height: 48px;
  border-radius: variables.$radius-10;
  padding: 0 variables.$spacing-10;
  overflow: hidden;
  width: 100%;
  margin: 10px;
  border: 1px dashed green;
  .delete {
    display: flex;
  }
}

.appFileUploadListItem__filename {
  z-index: 5;
}

.appFileUploadListItem__fileSize {
  color: variables.$color-grey-50;
  line-height: 100%;
}

.appFileUploadListItem__progress {
  position: absolute;
  background-color: variables.$color-grey-mid;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.delete {
  color: $alert-light;
  font-size: 20px;
  margin: 10px;
  &:hover {
    scale: 1.2;
    color: $alert-medium;
  }
}

.rowItemWrapper {
}
