@use '/src/utils/variables.scss';

.appFileUploadDragger :global {
  .ant-upload-drag {
    border: none !important;
    background: none !important;
    border-radius: variables.$radius-10 !important;

    .ant-upload-btn {
      padding: 30px !important;
      background-color: variables.$color-grey !important;

      p {
        margin: 0;
      }
    }
  }
}

.hintText {
  color: variables.$color-grey-mid;
}

.appFileUploadListWrapper {
  margin: variables.$spacing-40 0 0;
  &:nth-child(odd) {
    margin-right: 10px;
  }
}

.invisible {
  visibility: hidden;
}

.titleError {
  color: variables.$color-red !important;
}
.draggerError :global {
  .ant-upload-drag .ant-upload-btn {
    background-color: rgba(variables.$color-red, 0.2) !important;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23EC3463' stroke-width='3' stroke-dasharray='20%2c20' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: variables.$spacing-10;
  }
}
.actionButton {
  min-width: 160px;
  margin-top: variables.$spacing-40;
}

.errorMsg {
  color: red;
}
